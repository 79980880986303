import React, { useEffect } from 'react';
import CustomLoader from '../../components/loader/loader';

function RedirectToExternal({ to }) {

    useEffect(() => {
        // Perform the redirection to the external URL
        window.location.href = to;
    }, [to]);

    // Render nothing or a loading indicator while redirecting
    return <div className='d-flex flex-column justify-content-center align-items-center' style={{ height: '100vh' }}>
        <CustomLoader height="100px" />
        <h5 className='text-center'>Redirecting...</h5>
    </div>;
}

export default RedirectToExternal;
